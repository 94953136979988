<template>
  <div class="scoreDetail">
    <!-- 头部 -->
    <div class="scoreTop">
      <i class="el-icon-edit-outline"></i>
      Score Detail
    </div>
    <!-- 底部 -->
    <div class="scoreBtm">
      <el-table :data="tableData"
                border
                style="width: 100%">
        <el-table-column prop="points"
                         label="points"
                         width="100">
        </el-table-column>
        <el-table-column prop="detail"
                         label="detail"
                         width="1000">
        </el-table-column>
        <el-table-column prop="create_date"
                         label="create_date">
        </el-table-column>
      </el-table>
      <!-- <div class="scoreTable">
        <table cellspacing="0">
          <thead>
            <tr>
              <th>Points</th>
              <th>Source</th>
              <th>CreateDate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>add 10</td>
              <td>daily_check_in</td>
              <td>2020-07-30 02:27:04.0</td>
            </tr>
            <tr>
              <td>add 10</td>
              <td>daily_check_in</td>
              <td>2020-07-30 02:27:04.0</td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[10, 20, 50, 100]"
                         :page-size="curreneSize"
                         layout="total,sizes, prev, pager, next, jumper"
                         :total="currentTotal">
          </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  pointDetail // 积分详情
} from '../../api/buyerAfter'
// 日期格式化
import moment from 'moment'
export default {
  data () {
    return {
      // 分页
      currentPage: 1,
      curreneSize: 10,
      currentTotal: 0,
      // 积分列表
      tableData: []
    }
  },
  created () {
    // 积分详情
    pointDetail({
      page: this.currentPage,
      size: this.curreneSize,
      userId: localStorage.getItem('buyer-userId')
    }).then(result => {
      if (result.data.code === 200) {
        this.tableData = result.data.data.rows
        this.currentTotal = result.data.data.total
        this.tableData.map(v => {
          v.create_date = moment(v.create_date).format('YYYY-MM-DD hh:mm')
          if (v.source_type === 2) {
            v.points = -v.points
          } else {
            v.points = '+' + v.points
          }
        })
      }
    }).catch(err => {
      return err
    })
  },
  methods: {
    // 分页
    handleSizeChange (val) {
      this.curreneSize = val
      // (`每页 ${val} 条`)
      // 积分详情
      pointDetail({
        page: this.currentPage,
        size: val,
        userId: localStorage.getItem('buyer-userId')
      }).then(result => {
        if (result.data.code === 200) {
          this.tableData = result.data.data.rows
          this.currentTotal = result.data.data.total
          this.tableData.map(v => {
            v.create_date = moment(v.create_date).format('YYYY-MM-DD hh:mm')
            if (v.source_type === 2) {
              v.points = -v.points
            } else {
              v.points = '+' + v.points
            }
          })
        }
        // (this.tableData)
      }).catch(err => {
        return err
      })
    },
    handleCurrentChange (val) {
      // 积分详情
      pointDetail({
        page: val,
        size: this.curreneSize,
        userId: localStorage.getItem('buyer-userId')
      }).then(result => {
        if (result.data.code === 200) {
          this.tableData = result.data.data.rows
          this.currentTotal = result.data.data.total
          this.tableData.map(v => {
            v.create_date = moment(v.create_date).format('YYYY-MM-DD hh:mm')
            if (v.source_type === 2) {
              v.points = -v.points
            } else {
              v.points = '+' + v.points
            }
          })
        }
        // (this.tableData)
      }).catch(err => {
        return err
      })
    }
  }
}
</script>

<style lang="less" scoped>
.scoreDetail {
  //头部
  .scoreTop {
    padding: 12px 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c8ced3;
    line-height: 1.5;
    .el-icon-edit-outline {
      font-size: 14px;
      margin-right: 20px;
    }
  }
  //底部
  .scoreBtm {
    background-color: white;
    padding: 20px;
    /deep/ .el-table_1_column_2 {
      width: 300px !important;
    }
    .block {
      /deep/ .el-pagination {
        padding: 0;
        margin-top: 20px;
      }
      /deep/.el-pagination__total {
        border: 1px solid #dcdfe6;
        margin: 0;
      }
      /deep/ .btn-prev,
      /deep/ .btn-next {
        border: 1px solid #dcdfe6;
      }
      /deep/ .number {
        border: 1px solid #dcdfe6;
      }
    }
  }
}
</style>
